<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {errorCatcher} from "../../../helpers/error-catcher";

export default {

  components: {
    Layout,
    PageHeader
  },

  data() {
    return {
      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: "",

        items: []
      },

      showingStatuses: ["ORDER_COMPLETED"]
    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('core.monthly-order-reports.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "month", label: this.$t('core.monthly-order-reports.table.month')},
        {key: "income", label: this.$t('core.monthly-order-reports.table.gross-turnover'), formatter: value => {
            if (!value) {
              return "0.0"
            }

            return value.toFixed(2)
          }
        },
        {key: "netIncome", label: this.$t('core.monthly-order-reports.table.net-turnover'), formatter: value => {
            if (!value) {
              return "0.0"
            }

            return value.toFixed(2)
          }
        },
        {key: "netCost", label: this.$t('core.monthly-order-reports.table.net-cost'), formatter: value => {
            if (!value) {
              return "0.0"
            }

            return value.toFixed(2)
          }
        },
        {key: "cost", label: this.$t('core.monthly-order-reports.table.gross-cost'), formatter: value => {
            if (!value) {
              return "0.0"
            }

            return value.toFixed(2)
          }
        },

          //Zysk netto logistyka
        // Zysk netto Ecat
        // Zysk netto operatora
        {key: "profit", label: this.$t('core.monthly-order-reports.table.net-profit'), formatter: this.profitFormatter },
        {key: "ordersNumber", label: this.$t('core.monthly-order-reports.table.orders-number')},
        {key: "netAverage",
          label: "Średnia wartość zamówienia (netto)",
          formatter: this.netAverageFormatter
        },
        {key: "average",
          label: this.$t('core.monthly-order-reports.table.average-gross-order-value'),
          formatter: this.averageFormatter
        }
      ]
    },

    profitFormatter(value, key, item) {
      const income = item.income;
      const cost = item.cost;
      const profit = income - cost;
      if (!profit) {
        return "0.0"
      }

      return profit.toFixed(2);
    },

    netAverageFormatter(value, key, item) {
      const income = item.netIncome;
      const cost = item.ordersNumber;
      const average = income / cost;
      if (!average) {
        return "0.0"
      }

      return average.toFixed(2);
    },

    averageFormatter(value, key, item) {
      const income = item.income;
      const cost = item.ordersNumber;
      const average = income / cost;
      if (!average) {
        return "0.0"
      }

      return average.toFixed(2);
    },

    async loadReports() {
      try {
        const {data} = await axios.get(`/shop-order/reports`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            statuses: this.showingStatuses.join(",")
          }
        });

        this.table.items = data
        this.table.totalRows = data.length;
        this.table.rows = data.length;
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      return this.table.items
    },

    changeStatuses(statuses) {
      this.showingStatuses = statuses
      this.$refs.table.refresh()
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('core.monthly-order-reports.title')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="button-items">
              <a @click="changeStatuses(['ORDER_CANCELED'])" class="btn btn-danger mb-2">Zlicz tylko anulowane</a>
              <a @click="changeStatuses(['ORDER_IN_PROGRESS'])" class="btn btn-warning mb-2">Zlicz tylko w realizacji</a>
              <a @click="changeStatuses(['ORDER_COMPLETED'])" class="btn btn-success mb-2">Zlicz tylko zrealizowane</a>
              <a @click="changeStatuses(['ORDER_IN_PROGRESS', 'ORDER_COMPLETED'])" class="btn btn-info mb-2">Zlicz w realizacji i zrealizowane</a>
            </div>

            <ecat-table
                ref="table"
                :fields="getFields()"
                :items="loadReports"
                :pagination-top="true"
                :table="table"
            />
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>